import React from 'react';
import type { ComponentChildren } from 'preact';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames/bind';
import 'normalize.css';
import 'styles/global.scss';
import 'styles/theme.scss';
import CatalunLogoWhite from 'images/catalun-logo-white.svg';
import MoonIcon from 'images/icons/tiny-moon.svg';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/components/core/page.module.scss';

const cx = classNames.bind(styles);

interface PageHeadProps {
  children: ComponentChildren;
}

interface PageProps {
  className?: string;
  children: ComponentChildren;
}

export function PageHead({ children }: PageHeadProps) {
  return (
    <>
      <title id={HeadTags.siteTitle}>Catalün Village</title>
      {children}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="icon" href="/favicon.ico" sizes="any" />
      {/* 32×32 */}
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {/* 180×180 */}
      <link rel="manifest" href="/manifest.webmanifest" />
      <link
        href="https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Bangers&display=fallback"
        rel="stylesheet"
      />
    </>
  );
}

function Page({ className, children }: PageProps) {
  const location = useLocation();
  return (
    <>
      <header className={styles.pageHeader}>
        <Link to="/">
          <img src={CatalunLogoWhite} className={styles.centerLogo} alt="Catalün Village" />
        </Link>
        <nav className={styles.navList}>
          {/*
          <li>
            <img src={MoonIcon} className={cx({ active: location.pathname === '/events' })} alt="Selected Tab" />
            <Link to="/events">Events Calendar</Link>
          </li>
          */}
          <li>
            <img
              src={MoonIcon}
              className={cx({ active: location.pathname === '/' })}
              alt={`Selected: ${location.pathname}`}
            />
            <Link to="/">Mailing List</Link>
          </li>
          <li>
            <img
              src={MoonIcon}
              className={cx({ active: location.pathname === '/signup' })}
              alt={`Selected: ${location.pathname}`}
            />
            <Link to="/signup">Camp Signup</Link>
          </li>
          <li>
            <img
              src={MoonIcon}
              className={cx({ active: location.pathname === '/past-events' })}
              alt={`Selected: ${location.pathname}`}
            />
            <Link to="/past-events">Past Events</Link>
          </li>
        </nav>
      </header>
      <main className={className}>{children}</main>
    </>
  );
}
export default Page;
