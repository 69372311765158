import React from 'react';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/events-calendar.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village</title>
    </PageHead>
  );
}

function EventsCalendarPage() {
  return (
    <Page className={styles.EventsCalendar}>
      <section className={styles.pageCenter}>
        <article className={styles.stayTuned}>
          <h1>Stay Tuned</h1>
        </article>
      </section>
    </Page>
  );
}

export default EventsCalendarPage;
